import React from "react";
import CV from "./CV";






function CVContainer() {
	return (
		<div id="cv"  >
			<CV />
		</div>
	);
}

export default CVContainer;
